<template>
  <div
    id="search-source-select"
    class="d-flex-center"
  >
    <img
      src="@icons/plane.svg"
      class="mr-25"
    >
    <v-select
      :value="valueProp"
      class="w-100 remove_action"
      multiple
      taggable
      :options="carriersOptions"
      label="key"
      :disabled="isDisabled"
      append-to-body
      :reduce="(option) => option?.key?.toUpperCase()"
      :calculate-position="withPopper"
      :placeholder="$t('flight.carriersSearchPlaceholder')"
      :filter-by="filterBy"
      @input="handleUpdate"
      @option:deselecting="handleDeselectingCarrier"
    >
      <template #selected-option="data">
        <span class="text-body font-weight-bold">{{ data?.key?.toUpperCase() }}</span>
      </template>

      <template #option="data">
        <span class="">
          <span class="font-weight-bolder mr-25">{{ data?.key?.toUpperCase() }}</span>
          <small v-if="data.value">({{ data.value }})</small>
        </span>
      </template>
      <template #no-options>
        {{ $t('noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { computed, toRefs } from '@vue/composition-api'
import { createPopper } from '@popperjs/core'

import store from '@/store'

export default {
  components: {
    vSelect,
  },
  props: {
    carriers: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    withPopper(dropdownList, component) {
      const dropdownWidth = '250px'
      dropdownList.style.width = dropdownWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup(props, { emit }) {
    const valueProp = toRefs(props).carriers

    const carriersOptions = computed(() => store.getters['globalConfig/getConfig']('airlines'))

    const handleUpdate = val => {
      emit('update:carriers', val)
    }

    function handleDeselectingCarrier(val) {
      const array = valueProp.value.filter(item => item !== val)
      handleUpdate(array)
    }

    function filterBy(option, label, search) {
      const searchUpper = search.toUpperCase()
      return option.value?.toUpperCase().includes(searchUpper) || option.key.toUpperCase().includes(searchUpper)
    }

    return {
      valueProp,
      handleDeselectingCarrier,
      handleUpdate,
      carriersOptions,
      filterBy,
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';

::v-deep .vs__search {
  height: 50px;
}

#search-source-select ::v-deep {

  .vs__search {
    height: auto;
  }

  .vs__deselect {
    border-radius: 4px;
    color: #EF5350;
  }

  .vs__selected {
    // background-color: rgb(230,244,252);
    background-color: transparent;
    color: $dark;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 4px 8px;
    flex-grow: 0;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.9rem;
  }

  .vs__selected-options {
    display: flex;
    // flex-wrap: nowrap;
  }

  .vs__dropdown-toggle {
    border: none;
  }
}

#search-source-select .remove_action ::v-deep{
  .vs__actions {
    display: none;
  }
}
</style>
